// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-ankieta-tsx": () => import("./../../../src/pages/ankieta.tsx" /* webpackChunkName: "component---src-pages-ankieta-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-example-tsx": () => import("./../../../src/pages/example.tsx" /* webpackChunkName: "component---src-pages-example-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-blog-page-blog-tsx": () => import("./../../../src/templates/blogPage/Blog.tsx" /* webpackChunkName: "component---src-templates-blog-page-blog-tsx" */),
  "component---src-templates-category-page-category-tsx": () => import("./../../../src/templates/categoryPage/Category.tsx" /* webpackChunkName: "component---src-templates-category-page-category-tsx" */),
  "component---src-templates-event-oazs-tsx": () => import("./../../../src/templates/event/OAZS.tsx" /* webpackChunkName: "component---src-templates-event-oazs-tsx" */),
  "component---src-templates-page-page-tsx": () => import("./../../../src/templates/page/Page.tsx" /* webpackChunkName: "component---src-templates-page-page-tsx" */),
  "component---src-templates-search-search-page-tsx": () => import("./../../../src/templates/search/SearchPage.tsx" /* webpackChunkName: "component---src-templates-search-search-page-tsx" */),
  "component---src-templates-stories-tsx": () => import("./../../../src/templates/stories.tsx" /* webpackChunkName: "component---src-templates-stories-tsx" */)
}

