import * as React from 'react'
import Helmet from 'react-helmet'
import {graphql, useStaticQuery} from 'gatsby'

import '../../styles/index.scss';
import styles from "./Layout.module.scss";
import Header from '../Header/Header'
import Page from "../Page/Page";
import Footer from "../Footer/Footer";
import CookiesModal from "../CookiesModal/CookiesModal";
import favicon from "../../resources/favicon.png";

interface StaticQueryProps {
  site: {
    siteMetadata: {
      title: string
      description: string
      keywords: string
    }
  }
}

const Layout: React.FC = ({children, pageContext}) => {
  const data: StaticQueryProps = useStaticQuery(graphql`
      query IndexLayoutQuery {
        site {
          siteMetadata {
            title
            description
          }
        },
        allContentfulPages (filter: { category: {category: {eq: "stories"}}}) {
        edges {
          node {
            slug,
          }
        }
       }
      }
    `)
  const isEventLayout = pageContext.layout === "event";
  return <div className={styles.layout}>
    <Helmet
      title={data.site.siteMetadata.title}
      meta={[
        {name: 'description', content: data.site.siteMetadata.description},
        {name: 'keywords', content: data.site.siteMetadata.keywords},
        // {name: 'google-site-verification', content: "s_51-HYqGpO198FrzUyJyisTejJxmbVDUqEYH1UGfnk"}
      ]}
    >
      <link rel="icon" href={favicon} />
      {/*<script data-ad-client="ca-pub-3024407437654179" async*/}
      {/*        src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>*/}
    </Helmet>
    <Header withoutEventBanner={isEventLayout}/>
    <div className={styles.wrapper} id={"inner-wrapper"}>
      <Page>
        {children}
      </Page>
    </div>
    <div><CookiesModal/></div>
    <Footer withoutLinks={isEventLayout} storiesSlug={data.allContentfulPages.edges[0].node.slug}/>
  </div>;
}

export default Layout
