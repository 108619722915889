import { faArrowRight, faBars } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { graphql, Link, navigate, useStaticQuery } from 'gatsby'
import * as React from 'react'
import { useState } from 'react'
import { slide as Menu } from 'react-burger-menu'
import logo from '../../resources/logo.png'
import { STATIC_TEXTS } from '../../resources/staticTexts'
import Search from '../Search/Search'
import styles from './Header.module.scss'

interface Query {
  allContentfulPages: AllContentfulBlogPost
}

interface AllContentfulBlogPost {
  edges: Edge[]
}

interface Edge {
  node: Page
}

interface Page {
  title: string
  slug: string
  order: number
  category: {
    category: string
  }
}

const Header: React.FC = ({ withoutEventBanner }) => {
  const [drawer, setDrawer] = useState(false)

  const toggleDrawer = (open: boolean) => {
    setDrawer(open)
  }

  const renderMenu = () => (
    <Menu
      burgerButtonClassName={styles.burger}
      crossButtonClassName={styles.close}
      isOpen={drawer}
      menuClassName={styles.menu}
      onClose={() => toggleDrawer(false)}
    >
      <Search id={'headerSearch'} className={styles.menuItem} onSearchCallback={() => toggleDrawer(false)} />
      {pages.allContentfulPages.edges
        .filter(edge => edge.node.order < 999)
        .map(edge => (
          <div
            key={edge.node.title}
            className={styles.menuItemHoverable}
            onClick={async () => {
              await navigate(`/page/${edge.node.slug}`)
              toggleDrawer(false)
            }}
          >
            <span>{edge.node.title}</span>
          </div>
        ))}
      <div
        key={'contact'}
        className={styles.menuItemHoverable}
        onClick={async () => {
          await navigate(`/contact`)
          toggleDrawer(false)
        }}
      >
        <span>{STATIC_TEXTS.contact}</span>
      </div>
      <Link
        to={'https://webinary.medicaexpert.pl/ce-azs/'}
        target={'_blank'}
        key={'event'}
        className={styles.eventOption}
        onClick={() => {
          toggleDrawer(false)
        }}
      >
        <div className={styles.message}>
          <span>{STATIC_TEXTS.azsCenter}</span>
          <span>{STATIC_TEXTS.azsCenterDesc}</span>
        </div>
        <FontAwesomeIcon icon={faArrowRight} />
      </Link>
    </Menu>
  )

  const renderEventInfo = () => (
    <Link to={'https://webinary.medicaexpert.pl/ce-azs/topic-for-atopic/'} target={'_blank'} className={styles.eventLink}>
      <div className={styles.eventInfoWrapper}>
        <div className={styles.eventInfo}>
          <div className={styles.date}>{STATIC_TEXTS.eventDate}</div>
          <div className={styles.titleWrapper}>
            <span className={styles.title}>{STATIC_TEXTS.eventTitle}</span>
            <span className={styles.subTitle}>{STATIC_TEXTS.eventSubTitle}</span>
          </div>
        </div>
        <div className={styles.readMore}>
          <span>{STATIC_TEXTS.readMore}</span>
        </div>
      </div>
    </Link>
  )

  const pages: Query = useStaticQuery(graphql`
    query {
      allContentfulPages(sort: { fields: order, order: ASC }) {
        edges {
          node {
            title
            slug
            order
            category {
              category
            }
          }
        }
      }
    }
  `)
  return (
    <header>
      {renderMenu()}
      <div className={styles.headerWrapper}>
        <div className={styles.header}>
          <div className={styles.button} onClick={() => toggleDrawer(true)}>
            <FontAwesomeIcon icon={faBars} />
            <span>{STATIC_TEXTS.menu}</span>
          </div>
        </div>

        {!withoutEventBanner && renderEventInfo()}
        <Link to={`/`}>
          <img src={logo} className={styles.logo} alt={STATIC_TEXTS.altLogo} />
        </Link>
      </div>
    </header>
  )
}

export default Header
