import * as React from 'react'
import styles from "./Footer.module.scss";
import {STATIC_TEXTS} from "../../resources/staticTexts";
import writeToUs from "../../resources/napisz-do-nas.png";
import yourStories from "../../resources/waszehistorie.png";
import {graphql, Link, useStaticQuery} from "gatsby";
import Container from "../Container/Container";
import {Query} from "../../pages";

interface Props {
  storiesSlug: string,
  withoutLinks?: boolean
}

const Footer = (props: Props): JSX.Element => {
  const pages: Query = useStaticQuery(graphql`
   query {
   allContentfulPages (sort: { fields: order, order: ASC } ) {
    edges {
      node {
        title,
        slug,
        order,
        category {
         category
        }
      }
    }
   }
  }`)
  return <div>
    {!props.withoutLinks && <Container>
      <div className={styles.links}>
        <Link to={`/contact`}><img src={writeToUs} alt={STATIC_TEXTS.writeToUs}/></Link>
        <Link to={`/page/${props.storiesSlug}`}><img src={yourStories} alt={STATIC_TEXTS.yourStories}/></Link>
      </div>
    </Container>}
    <footer className={styles.footer}>
      <div>
        {STATIC_TEXTS.footer}
      </div>
      <div>
        {pages?.allContentfulPages.edges.filter(edge => edge.node.order > 999)?.map(edge => (
          <Link to={`/page/${edge.node.slug}`} key={edge.node.title} className={styles.footerPages}>
            <span>{edge.node.title}</span>
          </Link>
        ))}
      </div>
      <div>
        <a href={`mailto:${STATIC_TEXTS.email}`} target={"_blank"} rel="noopener noreferrer">{STATIC_TEXTS.email}</a>
      </div>
    </footer>
  </div>
}

export default Footer;
