import React, {useEffect, useRef} from "react";
import {BLOCKS} from "@contentful/rich-text-types";
import styles from "../templates/blogPage/Blog.module.scss";
import {Node} from "../templates/blogPage/Blog";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileDownload} from "@fortawesome/free-solid-svg-icons";

export const usePrevious = (value: any) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export const onBreakpoints = (onMobile?: any, onDesktop?: any, onTablet?: any) => {
  if (typeof window != "undefined")
    if (window?.innerWidth <= 768) {
      onMobile?.();
    } else if (window?.innerWidth >= 1200) {
      onDesktop?.();
    } else {
      onTablet?.();
    }
}

export const richTextOptions = (imageClassName?: string) => ({
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: (node: Node) => {
      return renderFileOrImage(node.data?.target?.file?.url, node.data?.target?.file?.fileName, imageClassName);
    },
    [BLOCKS.EMBEDDED_ENTRY]: (node: Node) => {
      const html = node.data?.target?.table?.childMarkdownRemark?.html;
      return html ? <div className={styles.tableWrapper}
                         dangerouslySetInnerHTML={{__html: html}}/> : node;
    }
  }
});

const renderFileOrImage = (url?: string, fileName?: string, imageClassName?: string): JSX.Element => {
  const imagesFormats = ["jpg", "jpeg", "png", "svg"];
  if (imagesFormats.some(format => url?.endsWith(format))) {
    return <div className={imageClassName || styles.image}><img src={url} alt={fileName}/></div>
  } else {
    return <a href={url} target={"_blank"}>
      <div className={styles.downloads}><FontAwesomeIcon icon={faFileDownload}/><span>{fileName}</span></div>
    </a>
  }
}
