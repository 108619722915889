import * as React from 'react'
import styles from "./Page.module.scss";
import classNames from "classnames";


interface PageProps {
  className?: string
}

const Page: React.FC<PageProps> = ({children, className}) =>
  <div className={classNames(className, styles.page)}>{children}</div>

export default Page
