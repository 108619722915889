import * as React from 'react'
import {useState} from 'react'
import {STATIC_TEXTS} from "../../resources/staticTexts";
import styles from "./CookiesModal.module.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faTimes} from "@fortawesome/free-solid-svg-icons";
import {Link} from "gatsby";
import {useCookie} from "../../utils/useCookie";

const CookiesModal: React.FC = () => {
  const [cookie, updateCookie] = useCookie("cookiesAccepted");
  let areCookiesAcknowledged = cookie === "true";

  const [visible, setModalVisible] = useState(!areCookiesAcknowledged);

  const onClose = () => {
    updateCookie("true", {expires: new Date(2056205060000)});
    setModalVisible(false);
  }


  return visible ? <div className={styles.cookiesModal}>
    <div>{STATIC_TEXTS.cookiesUsage}
      <div>{STATIC_TEXTS.privacyPolicyPage} <Link to={"page/polityka-prywatnosci"}>{STATIC_TEXTS.privacyPolicy}</Link></div></div>
    <div className={styles.buttons}>
      <div className={styles.button} onClick={onClose}><FontAwesomeIcon icon={faCheck}/></div>
      <Link to={STATIC_TEXTS.google}>
        <div className={styles.closeButton}><FontAwesomeIcon icon={faTimes}/></div>
      </Link>
    </div>
  </div> : null;
}

export default CookiesModal;
