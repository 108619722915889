import * as React from 'react'
import {useEffect, useRef, useState} from 'react'
import styles from "./Search.module.scss";
import {STATIC_TEXTS} from "../../resources/staticTexts";
import {navigate} from "gatsby";
import {usePrevious} from "../../utils/Utils";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faSearch} from '@fortawesome/free-solid-svg-icons'

export interface Props {
  className?: string,
  id?: string,
  onSearchCallback?: () => void,
  value?: string
}

const Search: React.FC<Props> = ({className, id, value, onSearchCallback}) => {
  const [searchValue, setSearchValue] = useState<string>();

  const isInitialMount = useRef(true);
  const inputRef = useRef();
  const prevValue = usePrevious(value)

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      if (value && !searchValue) {
        setSearchValue(value);
      }
    } else {
      if (prevValue !== value) {
        setSearchValue(value);
      }
    }
  });

  const keyPress = (e) => {
    if (e.key === "Enter") {
      onSearch()
    }
  }

  const blur = () => {
    inputRef.current.blur();
  }

  const onSearch = () => {
    if (!!searchValue && searchValue?.trim() !== "") {
      if (onSearchCallback) {
        onSearchCallback();
      }
      blur();
      navigate(`/page/search?keyword=${searchValue}`);
    }
  }


  return <div id={id} className={[styles.search, className].join(" ")}>
    <input
      ref={inputRef}
      type={"text"}
      onKeyPress={keyPress}
      value={searchValue}
      onChange={(event) => setSearchValue(event.target.value)}
      placeholder={STATIC_TEXTS.search}/>
    <div className={styles.button} onClick={onSearch}>
      <FontAwesomeIcon icon={faSearch}/></div>
  </div>;
}

export default Search
