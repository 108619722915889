import * as React from 'react'
import styles from "./Container.module.scss";
import classNames from "classnames";
import {RefObject} from "react";

interface ContainerProps {
  className?: string,
  ref?: RefObject<any>
}

const Container: React.FC<ContainerProps> = ({children, className, ref}) =>
  <div ref={ref} className={classNames(className, styles.container)}>{children}</div>

export default Container
